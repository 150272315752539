.headerContainer {
    display: flex;
    justify-content: space-around;
    padding: 3px 5px;
}

.title {
    margin: 0px;
    flex-grow: 1;
}

