.heroCard {
    position: relative;
}

.playerName {
    position: absolute;
    color: white;
    top: 1px;
    right: 10px;
    margin: 10px;
}

.heroName {
    position: absolute;
    color: white;
    bottom: 1px;
    left: 6px;
    margin: 10px;
}

.heroRemove {
    position: absolute;
    color: white;
    top: 1px;
    left: 1px;
    margin: 10px;

    background-color: transparent;
    background-repeat: no-repeat;
}

.heroImg {
    height: 28vh;
    border: thick solid black;
    margin-right: 15px;
}

.heroImg.unavailable {
    filter: blur(3px);
}

.heroImg.defeated {
    filter: grayscale(100%);
}

.heroImg.unavailableDefeated {
    filter: grayscale(100%) blur(3px);
}

.heroImg.selected {
    filter: grayscale(0%);
    border: thick solid gold;
}
