.modesContainer {
    display: flex;
    flex-wrap: wrap;
}

.mode {
    padding: 15px 32px;
    color: white;
    border: thin solid;
    flex-grow: 1;
    background-color: #7BA77D;
}

.mode.selected {
    background-color: #006F05;
}

.bossesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.actionButtonContainer {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 4px;
}
