.boss {
    position: relative;
}

.fightCounter {
    position: absolute;
    color: white;
    top: 1px;
    right: 6px;
    margin: 10px;
}

.bossName {
    position: absolute;
    color: white;
    bottom: 10px;
    left: 10px;
}

.bossImg {
    height: 24vh;
    border: medium solid #8a8a8a;
}

.bossImg.selected {
    border: medium solid gold;
}

.bossImg.defeated {
    filter: grayscale(100%);
    border: medium solid #8a8a8a;
}
