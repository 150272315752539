.container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
}

.modalContent {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    overflow-y: auto;
}

@media only screen and (min-width: 768px) {
    .container {
        padding-top: 3vh;
    }

    .modalContent {
        width: 80%;
        max-height: 90vh;
    }
}
